import * as React from "react";
import { Helmet } from "react-helmet";

// styles
const pageStyles = {
  color: "#232129",
  padding: 20,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const headingStyles = {
  fontSize: 24,
};

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta
          name="description"
          content="Join Really Good — The Candidate Experience App"
        />
        <title>Home Page</title>
      </Helmet>
      <h1 style={headingStyles}>Hey there! 👋</h1>
    </main>
  );
};

export default IndexPage;
